@font-face {
  font-family: Wremena-Light;
  src: url("b1d025a7f727adf843c00feb28499910.woff") format("woff");
}
@font-face {
  font-family: IDEOLOGY;
  src: url("769fe087486730d5e6cc393f74d7974e.woff") format("woff");
}
@font-face {
  font-family: IDEOLOGY-Expanded;
  src: url("e6a66344046c9ed88772933290dd6e0d.woff") format("woff"), url("f0829cb3b8e014b9fd8d898023288f1d.woff2") format("woff2");
}
@font-face {
  font-family: IDEOLOGY-RegularBold;
  src: url("6728cbbfb92946c3c616d3b1db5da86c.woff") format("woff"), url("c41c2a6b9538d146a0430e361bf1eb26.woff2") format("woff2");
}
@font-face {
  font-family: MoreOrLessGrotesque;
  src: url("f5a5064b32ef83e1fdd8a88cc24230c3.woff") format("woff"), url("73d2b4d66affe6903dbf60c8a7a9ba7b.woff2") format("woff2");
}
.text-quote {
  font-family: IDEOLOGY, sans-serif;
  font-size: 1.96078431372549rem;
  line-height: 1.083333333333333;
}
@media (max-width: 600px) {
  .text-quote {
    font-size: 0.735294117647059rem;
    line-height: 1;
  }
}
.text-big {
  font-family: IDEOLOGY, sans-serif;
  font-size: 1.225490196078431rem;
  line-height: 1.1;
  text-transform: uppercase;
}
.text-body {
  font-family: Wremena-Light;
  font-size: 1.225490196078431rem;
  line-height: 1.1;
}
@media (max-width: 600px) {
  .text-body {
    font-size: 0.898692810457516rem;
    line-height: 1.045454545454545;
  }
}
.text-highlight {
  font-family: IDEOLOGY-RegularBold, sans-serif;
  font-size: 1.021241830065359rem;
  line-height: 1.32;
}
@media (max-width: 600px) {
  .text-highlight {
    font-size: 0.735294117647059rem;
    line-height: 1;
  }
}
.text-header {
  font-family: IDEOLOGY, sans-serif;
  font-size: 4.084967320261438rem;
  line-height: 0.97;
}
@media (max-width: 600px) {
  .text-header {
    font-size: 1.388888888888889rem;
    line-height: 1;
  }
}
.text-subheader {
  font-family: IDEOLOGY, sans-serif;
  font-size: 2.369281045751634rem;
  line-height: 1.03448275862069;
}
@media (max-width: 600px) {
  .text-subheader {
    font-size: 1.388888888888889rem;
    line-height: 1;
  }
}
.text-section-header {
  font-family: IDEOLOGY-Expanded, sans-serif;
  font-size: 0.980392156862745rem;
  line-height: 1;
}
@media (max-width: 600px) {
  .text-section-header {
    font-size: 0.65359477124183rem;
  }
}
.text-site-header {
  font-family: IDEOLOGY, sans-serif;
  font-size: 0.980392156862745rem;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  .text-site-header {
    font-size: 0.571895424836601rem;
  }
}
.text-caption {
  font-family: Wremena-Light;
  font-size: 0.816993464052288rem;
  line-height: 1;
}
@media (max-width: 600px) {
  .text-caption {
    font-size: 0.571895424836601rem;
  }
}
.text-feed-body {
  font-family: Wremena-Light;
  font-size: 1.225490196078431rem;
  line-height: 1.1;
}
@media (max-width: 600px) {
  .text-feed-body {
    font-size: 0.571895424836601rem;
    line-height: 1.285714285714286;
  }
}
.english,
.panel-left,
.panel-right {
  background: #fff;
}
.english,
.russian {
  transition: left 0.3s background 0.3s, color 0.3s;
}
.active a,
a {
  text-decoration: none;
}
.panel-container .text span {
  text-decoration: underline;
}
.lang,
.nav {
  z-index: 999;
}
.en,
.nav,
.ru {
  text-transform: uppercase;
}
html {
  font-size: 1.7vw;
  line-height: 1.3;
  font-feature-settings: "liga", "dlig";
  font-kerning: normal;
  font-family: Wremena-Light;
}
@media (max-width: 600px) {
  html {
    font-size: 6.528vw;
  }
}
body,
html {
  overflow-x: hidden;
}
@-ms-viewport {
  width: device-width;
}
h1 {
  text-align: center;
  font-family: IDEOLOGY, sans-serif;
  font-family: IDEOLOGY, sans-serif;
  font-size: 2.369281045751634rem;
  line-height: 1.03448275862069;
}
@media (max-width: 600px) {
  h1 {
    font-size: 1.388888888888889rem;
    line-height: 1;
  }
}
h2 {
  font-family: IDEOLOGY, sans-serif;
  font-size: 2.369281045751634rem;
  line-height: 1.03448275862069;
  text-align: center;
  text-stroke: 1px #000;
  text-fill-color: transparent;
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 600px) {
  h2 {
    font-size: 1.388888888888889rem;
    line-height: 1;
  }
}
@media (max-width: 600px) {
  h2 {
    margin-top: 0.204248366013072rem;
    font-size: 0.980392156862745rem;
  }
}
.nav,
sup {
  font-family: IDEOLOGY;
}
.date {
  text-stroke: 1px #000;
  text-fill-color: transparent;
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
  display: block;
  font-variant-numeric: oldstyle-nums;
  letter-spacing: -0.081699346405229rem;
}
.nav-container,
.panel-container {
  display: flex;
  width: 156.66666666666666vw;
  height: 100%;
  z-index: 10000;
}
@media (max-width: 600px) {
  .nav-container,
  .panel-container {
    width: 162.66666666666666vw;
  }
}
.page-container {
  position: relative;
}
.panel-container {
  left: 0;
  display: flex;
  flex-direction: row;
}
.nav-container {
  display: flex;
  flex-direction: row;
  top: 0;
  position: fixed;
}
.english-nav,
.nav {
  display: flex;
}
article.panel-container:first-of-type .panel {
  padding-top: 5.514705882352941rem;
}
@media (max-width: 600px) {
  article.panel-container:first-of-type .panel {
    padding-top: 3.063725490196078rem;
  }
}
.panel {
  width: 78.33333333333333vw;
  padding: 1.225490196078431rem 2.450980392156863rem 6.127450980392156rem;
}
@media (max-width: 600px) {
  .panel {
    padding: 1.225490196078431rem 0.490196078431373rem 2.450980392156863rem;
    width: 81.33333333333333vw;
  }
}
.quote-section + .panel-container .panel {
  padding-top: 6.127450980392156rem;
}
@media (max-width: 600px) {
  .quote-section + .panel-container .panel {
    padding-top: 2.450980392156863rem;
  }
}
.panel-container + .panel-container .panel {
  padding-top: 0rem;
}
footer .panel {
  padding: 1.5vw 3vw;
}
.inner p:first-of-type {
  text-indent: 0;
}
.inner p {
  text-indent: 2.042483660130719rem;
  font-family: Wremena-Light;
  font-size: 1.225490196078431rem;
  line-height: 1.1;
  hyphens: auto;
}
@media (max-width: 600px) {
  .inner p {
    font-size: 0.571895424836601rem;
    line-height: 1.285714285714286;
  }
}
.text {
  line-height: 1.15;
}
figure {
  padding: 1.225490196078431rem 0;
  width: 100%;
}
@media (max-width: 600px) {
  figure {
    padding: 0.326797385620915rem 0;
  }
}
figure img,
figure video {
  width: 100%;
  object-fit: contain;
  object-fit: cover;
  object-position: top;
}
.english {
  color: #000;
}
.russian {
  background: #000;
  color: #fff;
}
.active,
.active a {
  transition: background 0.3s, color 0.3s;
  background: #fff;
  color: #000;
}
.not-active,
.not-active a {
  transition: background 0.3s, color 0.3s;
  background: #000;
}
.panel.not-active .inner {
  pointer-events: none;
}
.active img,
.active video {
  filter: invert(0) grayscale(100%);
}
.not-active {
  color: #fff;
}
.not-active a {
  color: #fff !important;
}
.english-active,
.russian-active {
  transition: left 0.3s, background 0.3s, color 0.3s;
}
.not-active img,
.not-active video {
  filter: invert(100%) grayscale(100%);
}
.english-active {
  left: 0 !important;
}
.russian-active {
  left: -56.666666666666664vw !important;
}
@media (max-width: 600px) {
  .russian-active {
    left: -62.66666666666667vw !important;
  }
}
a,
a:hover {
  transition: border-color 0.3s;
}
sup {
  font-variant-position: super;
  vertical-align: baseline;
  position: relative;
  top: -0.3em;
  font-size: 0.7em;
  border-bottom: 0.1em solid;
  margin: 0 0.3em;
}
.nav {
  height: auto;
  width: 156.66666666666666vw;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  padding: 0;
  font-family: IDEOLOGY, sans-serif;
  font-size: 0.980392156862745rem;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  .nav {
    font-size: 0.571895424836601rem;
  }
}
@media (max-width: 600px) {
  .nav {
    width: 162.66666666666666vw;
  }
}
.english-nav,
.russian-nav {
  width: 100vw;
  padding: 1vw 2.450980392156863rem;
  align-content: space-between;
  height: 100%;
}
@media (max-width: 600px) {
  .english-nav,
  .russian-nav {
    padding: 0.571895424836601rem;
  }
}
a {
  color: #000;
  border-color: transparent;
}
a:hover {
  border-color: #000;
}
.english-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0;
}
.russian-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-bottom: 0;
}
.english-nav.active,
.english-nav.not-active,
.russian-nav.active,
.russian-nav.not-active {
  background: 0 0;
  padding-bottom: 5vw;
}
@media (max-width: 600px) {
  .english-nav.active,
  .english-nav.not-active,
  .russian-nav.active,
  .russian-nav.not-active {
    padding-bottom: 0.490196078431373rem;
  }
}
.english-nav:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 78.33333333333333vw;
  height: 100%;
  background: linear-gradient(0deg, transparent 0, #000 50%);
  transition: opacity 0.3s, color 0.3s;
  opacity: 0;
}
@media (max-width: 600px) {
  .english-nav:after {
    width: 81.33333333333333vw;
  }
}
.english-nav:before,
.russian-nav:after {
  background: linear-gradient(0deg, rgba(255,255,255,0) 0, #fff 50%);
  position: absolute;
  content: "";
  top: 0;
  width: 78.33333333333333vw;
  height: 100%;
  transition: opacity 0.3s, color 0.3s;
}
@media (max-width: 600px) {
  .english-nav:before,
  .russian-nav:after {
    height: 150%;
    width: 81.33333333333333vw;
  }
}
.english-nav.not-active:after {
  opacity: 1;
  transition: opacity 0.3s, color 0.3s;
}
.russian-nav:after {
  left: 78.33333333333333vw;
  opacity: 0;
}
@media (max-width: 600px) {
  .russian-nav:after {
    left: 81.33333333333333vw;
  }
}
.russian-nav.active:after {
  opacity: 1;
  transition: opacity 0.3s, color 0.3s;
}
.english-nav:before {
  left: 0;
  opacity: 0;
}
.english-nav.active:before {
  opacity: 1;
  transition: opacity 0.3s, color 0.3s;
}
.russian-nav:before {
  position: absolute;
  content: "";
  top: 0;
  left: 78.33333333333333vw;
  width: 78.33333333333333vw;
  height: 100%;
  background: linear-gradient(0deg, transparent 0, #000 50%);
  transition: opacity 0.3s, color 0.3s;
  opacity: 0;
}
@media (max-width: 600px) {
  .russian-nav:before {
    height: 150%;
    left: 81.33333333333333vw;
    width: 81.33333333333333vw;
  }
}
.bubble-wrap,
canvas {
  width: 78.33333333333333vw;
  height: 100vh;
}
@media (max-width: 600px) {
  .bubble-wrap,
  canvas {
    width: 81.33333333333333vw;
  }
}
.russian-nav.not-active:before {
  opacity: 1;
  transition: opacity 0.3s, color 0.3s;
}
.english-nav.active a,
.english-nav.not-active a,
.russian-nav.active a,
.russian-nav.not-active a {
  background: 0 0;
  z-index: 999;
}
.lang {
  position: relative;
}
.en {
  color: #fff;
}
.hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: background 0.3s, color 0.3s;
}
.en,
.ru {
  cursor: pointer;
  margin-left: 30vw;
  opacity: 1;
  transition: opacity 0.3s, background 0.3s, color 0.3s;
}
img,
video,
.quote-section {
  position: relative;
  z-index: 10001 !important;
}
.bubble-wrap {
  transition: left 0.3s;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0 !important;
  right: 0;
  bottom: 0 !important;
  pointer-events: none;
  margin: 0;
  padding: 0;
  filter: blur(20px);
  opacity: 0.6;
  animation: fadeInOut 10s infinite;
}
.russian-blob {
  left: 20vw;
  transition: left 0.3s;
}
@media only screen and (max-width: 900px) {
  .date {
    text-stroke: 0.5px #000;
    text-fill-color: transparent;
    -webkit-text-stroke: 0.5px #000;
    -webkit-text-fill-color: transparent;
    display: block;
  }
  h1 {
    font-size: 7vw;
  }
  .en,
  .ru {
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  h1 {
    font-size: 0.980392156862745rem;
    line-height: 1;
  }
}
.quote-section {
  width: 156.66666666666666vw;
  display: flex;
}
@media (max-width: 600px) {
  .quote-section {
    width: 162.66666666666666vw;
  }
}
.quote-section-item {
  flex: 1;
  padding: 1.225490196078431rem 2.859477124183007rem 2.655228758169935rem 2.450980392156863rem;
  color: #fff;
  position: relative;
}
@media (max-width: 600px) {
  .quote-section-item {
    padding: 0.490196078431373rem 0.326797385620915rem 0.490196078431373rem 0.65359477124183rem;
  }
}
.quote-section-item p {
  position: relative;
}
.quote-section-item span {
  color: inherit;
  border-color: inherit;
  text-decoration: underline;
}
.quote-section-item-cover,
.quote-section-item-cover-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(1.633986928104575rem);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.quote-section-item-cover {
  background-image: url("02949a9defbcdafe7cdcbf9d1b76a201.jpg");
}
@media (max-width: 600px) {
  .quote-section-item-cover {
    background-image: url("ad9bffe5229973161b64a4824791403e.jpg");
  }
}
.quote-section-item-cover-2 {
  background-image: url("94fe56562a550d2ccdf3d01eac4913c2.jpg");
}
.english-active .quote-section-item--russian {
  filter: invert(100%) grayscale(100%);
  background-color: #fff;
}
.english-active .quote-section-item--russian a {
  pointer-events: none;
}
.russian-active .quote-section-item--english {
  filter: invert(100%) grayscale(100%);
  background-color: #fff;
}
.russian-active .quote-section-item--english a {
  pointer-events: none;
}
.quote-example .quote-section-item::before {
  background-image: url("40c1fd2b4cc25484399a7176b1bb0dc9.jpg");
}
.article-h1 {
  max-width: 30.637254901960784rem;
  margin: 0 auto;
}
.article-read-full-box {
  margin-top: 1.225490196078431rem;
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .article-read-full-box {
    margin-top: 0.490196078431373rem;
  }
}
.article-read-full {
  display: inline-block;
  border: 0.040849673202614rem solid !important;
  text-align: center;
  padding: 0.531045751633987rem 0.490196078431373rem 0.449346405228758rem;
  line-height: 1;
  color: #000;
  text-decoration: none !important;
}
@media (max-width: 600px) {
  .article-read-full {
    padding: 0.408496732026144rem 0.490196078431373rem 0.367647058823529rem;
  }
}
.english-active .article-read-full--russian {
  background: #fff;
}
.russian-active .article-read-full--english {
  background: #fff;
}
.authors {
  text-align: center;
}
.authors span {
  margin-top: 0.204248366013072rem;
  display: block;
}
.authors p {
  text-align: left;
  margin-top: 0.816993464052288rem;
  hyphens: manual;
}
.block {
  display: block;
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
}
@-webkit-keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
}
@-o-keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
}
